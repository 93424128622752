import { formatImagePath } from "components/utils/functions";
import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";
import Video from "yet-another-react-lightbox/plugins/video";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";




export default function MediasShower({open, handleClose, medias, defaultIndex}) {

    const fullscreenRef = React.useRef(null);
    const zoomRef = React.useRef(null);


  return (
    <>
      <Lightbox
        open={open}
        close={handleClose}
        index={defaultIndex}
        plugins={[Download, Video, Fullscreen, Zoom, Thumbnails]}
        slides={medias?.map((media) => ({
          src: formatImagePath(media.file_url),
          alt: formatImagePath(media.name),
        }))}
        fullscreen={{ ref: fullscreenRef }}
        on={{
        click: () => fullscreenRef.current?.enter(),
        }}
      />
    </>
  );
}