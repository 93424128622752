/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/attendants/data/authorsTableData";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import ManageAttendant from "./form/ManageAttendant";
import useSWR from "swr";
import { URLS } from "services/urls";

// Material Dashboard 2 React components
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import { AirplaneTicket, Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import DeleteAttendant from "./components/Delete";
import ImportAttendants from "./form/ImportAttendants";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { API } from "services/axios";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { formatImagePath } from "components/utils/functions";

const Author = ({ image, name, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);

const Job = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      N de table: {title}
    </MDTypography>
    <MDTypography variant="caption">Siège: {description}</MDTypography>
  </MDBox>
);


const generateRows = (data, handleEditSuccess, handleViewAttendant, downloading) => {

  if (data) {
    return data.map((attendant) => {
      return {
        author: <Author image={`https://eu.ui-avatars.com/api/?name=${attendant.full_name}&size=250`} name={attendant.full_name} email={attendant.username} />,
        function: <Job title={attendant.table_number} description={attendant.table_seat} />,
        is_comfirmed: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={attendant.is_comfirmed ? "Oui" : "Non"} color={attendant.is_comfirmed ? "success" : "warning"} variant="gradient" size="sm" />
          </MDBox>
        ),
        is_present: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={attendant.is_present ? "Oui" : "Non"} color={attendant.is_present ? "success" : "warning"} variant="gradient" size="sm" />
          </MDBox>
        ),
        action: (
          <MDBox>
            <ManageAttendant toEdit={attendant} onSuccess={handleEditSuccess} />
            <Tooltip title="Voir le billet">
              {downloading.includes(attendant?.access_code) ? <CircularProgress size={20} color="inherit" /> : <IconButton size="small" onClick={() => handleViewAttendant(attendant.access_code)}>
                <ConfirmationNumberIcon />
              </IconButton>}
            </Tooltip>
            <DeleteAttendant attendant={attendant} onSuccess={handleEditSuccess} />
          </MDBox>
        ),
      }
    })
  } else {
    return []
  }

}

function AttendantsList() {
  const { columns, rows } = authorsTableData();
  const { data, isLoading, mutate } = useSWR(URLS.ATTENDANT.list);

  const [downloading, setDownloading] = useState([]);

  const handleEditSuccess = () => mutate();


  const handleViewAttendant = (ticketId) => {
    setDownloading((prev) => [...prev, ticketId]);
    API.get(URLS.ATTENDANT.viewTicket(ticketId)).then(response => {
      console.log(response);
      setDownloading((prev) => prev.filter((id) => id !== ticketId));

      const a = document.createElement("a");
      a.href = formatImagePath(response.data.pdf_file);
      a.download = response.data.name;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      
      document.body.removeChild(a);
      
    }).catch(err => {
      console.log(err);
      // enqueueSnackbar(err.response.data.detail, { variant: "error" });
      setDownloading((prev) => prev.filter((id) => id !== ticketId));
    })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: { xs: "column", lg: "row" } }}>
                  <MDTypography variant="h6" color="white">
                    Liste des invités
                  </MDTypography>
                  <MDBox gap={1} sx={{ display: "flex", alignItems: "center", flexDirection: "row", marginTop: { xs: 3, lg: 0 } }}>
                    <ManageAttendant />
                    <ImportAttendants onSuccess={handleEditSuccess} />
                  </MDBox>
                </Box>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows: generateRows(data, handleEditSuccess, handleViewAttendant, downloading) }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AttendantsList;
