import React from 'react';
import { Box, styled } from '@mui/material';

const ProgressContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300],
    borderRadius: '5px',
    overflow: 'hidden',
    width: '100%',
    height: '10px',
}));

const ProgressBar = styled(Box)(({ value, theme }) => ({
    height: '100%',
    width: `${value}%`,
    backgroundColor: theme.palette.primary.main,
    transition: 'width 0.3s ease-in-out',
}));

const CustomLinearProgress = ({ value }) => {
    return (
        <ProgressContainer>
            <ProgressBar value={value} />
        </ProgressContainer>
    );
};

export default CustomLinearProgress;
