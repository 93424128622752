// src/VideoPlayer.js
import React, { useRef, useState } from 'react';
import { IconButton, Slider, Box, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const VideoPlayer = ({videoUrl}) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);

    const handlePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        const percent = (videoRef.current.currentTime / videoRef.current.duration) * 100;
        setProgress(percent);
        if (percent === 100) {
            setIsPlaying(false);
        }
    };

    const handleSeek = (event, newValue) => {
        const duration = videoRef.current.duration;
        videoRef.current.currentTime = (newValue / 100) * duration;
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <video
                ref={videoRef}
                src={videoUrl} // Replace with your video source
                onTimeUpdate={handleTimeUpdate}
                style={{ width: '100%', maxWidth: '600px' }}
                controls={false} // Disable default controls
            />
            <Box sx={{position: 'absolute', bottom: 0, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Box>
                <IconButton onClick={handlePlayPause} size='large'>
                    {(isPlaying) ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>
                </Box>
                <Box sx={{pr: 2, pl: 2, width: '100%'}}>

                    <Slider
                        value={progress}
                        onChange={handleSeek}
                        aria-labelledby="video-progress"
                        sx={{ width: '100%', py: .5}}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default VideoPlayer;
