import { SWRConfig } from 'swr';
import { API } from './axios';

const fetcher = (url) => API.get(url).then(res => res.data);
const ConfigSWR = (props) => {
  const {children} = props;

  return(
    <SWRConfig
    value={{
      fetcher: fetcher
    }}
    >
      {children}
    </SWRConfig>
  )
}

export default ConfigSWR;