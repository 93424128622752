import styled from "@emotion/styled";
import { Delete, Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { API } from "services/axios";
import { URLS } from "services/urls";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));


const DeleteAttendant = ({ attendant, onSuccess }) => {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteAttendant = () => {
        setIsLoading(true);
        API.delete(URLS.ATTENDANT.delete(attendant.id))
            .then((resp) => {
                console.log(resp.data);
                setIsLoading(false);
                enqueueSnackbar("L'invité a bien été supprimé", {
                    variant: "success",
                    autoHideDuration: 3000,
                    preventDuplicate: true,
                });
                if(onSuccess) {
                    onSuccess();
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                enqueueSnackbar("Une erreur est survenue lors de la suppression de l'invité", {
                    variant: "error",
                    autoHideDuration: 3000,
                    preventDuplicate: true,
                });
            })
    }

    return (
        <React.Fragment>
            
            <IconButton onClick={handleClickOpen} color="error" size="small">
              <Delete />
            </IconButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Supprimer l'invité
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <Close />
                </IconButton>
                <DialogContent dividers>
                    <MDBox mb={2}>
                        <MDTypography variant="h6" color="error">
                            Êtes-vous sûr de vouloir supprimer l'invité {attendant.full_name} ?
                        </MDTypography>
                    </MDBox>
                </DialogContent>
                <DialogActions>
                    <MDBox mt={4} mb={1}>
                        <MDButton disabled={isLoading} variant="gradient" color="error" fullWidth onClick={handleDeleteAttendant}>
                            Supprimer l'invité
                        </MDButton>
                    </MDBox>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}

export default DeleteAttendant;