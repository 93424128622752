import _ from "lodash";
import { URLS } from "services/urls";

let ls = {}

ls.set = window.localStorage.setItem.bind(localStorage);
ls.get = window.localStorage.getItem.bind(localStorage);

const getUserDetails = () => JSON.parse( window.localStorage.getItem("user-details"));
const setUserDetails = (details) => window.localStorage.setItem("user-details", JSON.stringify(details));

const formatImagePath = (path) => {
    return `${URLS.BASE_MEDIAS}${path}`
};


export {ls, setUserDetails, getUserDetails, formatImagePath }