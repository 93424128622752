/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { SnackbarProvider } from 'notistack'


// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import ConfigSWR from "services/swr";
import { AuthProvider } from "layouts/private/AuthContext";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <AuthProvider>
        <SnackbarProvider maxSnack={3} >
          <ConfigSWR>
            <App />
          </ConfigSWR>
        </SnackbarProvider>
      </AuthProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

