import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from '@mui/material/MenuItem';
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { API } from "services/axios";
import { URLS } from "services/urls";
import { enqueueSnackbar } from "notistack";
import { Edit, Password } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import Switch from "@mui/material/Switch";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const ticketTypes = [
    {
        value: 'SINGLE',
        label: 'Solo',
    },
    {
        value: 'COUPLE',
        label: 'Couple',
    }
];

export default function ManageAttendant({ toEdit, onSuccess }) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    // Form fields
    const [fullName, setFullName] = React.useState("");
    const [ticketType, setTicketType] = React.useState("SINGLE");
    // Available on edit
    const [isComfirmed, setIsComfirmed] = React.useState(false);
    const [isPresent, setIsPresent] = React.useState(false);
    const [tableNumber, setTableNumber] = React.useState(0);
    const [seatNumber, setSeatNumber] = React.useState(0);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleAddAttendant = () => {
        setIsLoading(true);
        if (toEdit) {
            API.put(URLS.ATTENDANT.update(toEdit.id), {
                ...toEdit,
                full_name: fullName,
                ticket_type: ticketType,
                is_comfirmed: isComfirmed,
                is_present: isPresent,
                table_number: tableNumber,
                seat_number: seatNumber,
            })
                .then((resp) => {
                    console.log(resp.data);
                    setIsLoading(false);
                    enqueueSnackbar("L'invité a bien été mis à jour", {
                        variant: "success",
                        autoHideDuration: 3000,
                        preventDuplicate: true,
                    });
                    if(onSuccess) {
                        onSuccess();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    enqueueSnackbar("Une erreur est survenue lors de la mise à jour de l'invité", {
                        variant: "error",
                        autoHideDuration: 3000,
                        preventDuplicate: true,
                    });
                })
        } else {
            API.post(URLS.ATTENDANT.add, {
                full_name: fullName,
                username: "fullName",
                password: fullName,
                password2: fullName,
                ticket_type: ticketType,
            })
                .then((resp) => {
                    console.log(resp.data);
                    setIsLoading(false);
                    enqueueSnackbar("L'invité a bien été ajouté", {
                        variant: "success",
                        autoHideDuration: 3000,
                        preventDuplicate: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    enqueueSnackbar("Une erreur est survenue lors de l'ajout de l'invité", {
                        variant: "error",
                        autoHideDuration: 3000,
                        preventDuplicate: true,
                    });
                })
        }
    }

    const handleIsComfirmed = () => setIsComfirmed(!isComfirmed);
    const handleIsPresent = () => setIsPresent(!isPresent);

    React.useEffect(() => {
        if (toEdit) {
            setFullName(toEdit.full_name);
            setTicketType(toEdit.ticket_type);
        }
    }, [toEdit]);

    return (
        <React.Fragment>
            {toEdit ?
                <IconButton size="small" onClick={handleClickOpen}>
                    <Edit />
                </IconButton>
                :
                <MDButton
                    variant="contained"
                    color="info"
                    size="small"
                    sx={{ mr: 1 }}
                    onClick={handleClickOpen}
                >
                    Ajouter un invité
                </MDButton>}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {toEdit ? "Modifier l'invité: " + toEdit.full_name : "Ajouter un invité"}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <MDBox mb={2}>
                        <MDInput type="text" label="Nom Complet" fullWidth value={fullName} onChange={(e) => setFullName(e.target.value)} />
                    </MDBox>
                    <MDBox mb={2}>

                        <MDInput
                            id="outlined-select-currency"
                            select
                            label="Type de billet"
                            fullWidth
                            value={ticketType}
                            onChange={(e) => setTicketType(e.target.value)}
                            sx={{ "& .MuiInputBase-root": { height: 40 } }}
                        >
                            {ticketTypes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </MDInput>
                    </MDBox>
                    <MDBox mb={2}>
                        <MDInput type="text" label="N de Table" fullWidth value={tableNumber} onChange={(e) => setTableNumber(e.target.value)} />
                    </MDBox>
                    <MDBox mb={2}>
                        <MDInput type="text" label="Numéro de place" fullWidth value={seatNumber} onChange={(e) => setSeatNumber(e.target.value)} />
                    </MDBox>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                        <Switch checked={isComfirmed} onChange={handleIsComfirmed} />
                        <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            onClick={handleIsComfirmed}
                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                           &nbsp; Confirmer l'invitation
                        </MDTypography>
                    </MDBox>
                    <MDBox display="flex" alignItems="center" ml={-1}>
                        <Switch checked={isPresent} onChange={handleIsPresent} />
                        <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            onClick={handleIsPresent}
                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                           &nbsp; Invité en salle
                        </MDTypography>
                    </MDBox>
                </DialogContent>
                <DialogActions>
                    <MDBox mt={4} mb={1}>
                        <MDButton disabled={isLoading} variant="gradient" color="info" fullWidth onClick={handleAddAttendant}>
                           {
                               toEdit ? "Modifier l'invité" : "Ajouter l'invité"
                           }
                        </MDButton>
                    </MDBox>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
