import React from 'react';
import { Card, CardContent, Typography, Button, Avatar, Box } from '@mui/material';
import { blue } from '@mui/material/colors';
import MDButton from 'components/MDButton';
import { API } from 'services/axios';
import { URLS } from 'services/urls';

const ProfileCard = ({attendant, handleReset}) => {

    const [isLoading, setIsLoading] = React.useState(false);

    const handleMarkPresent = () => {
        setIsLoading(true);
        API.patch(URLS.ATTENDANT.update(attendant.id), { is_present: true }).then(() => {
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        });
    }
  return (
    <Card sx={{ maxWidth: 345, boxShadow: 3 }}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar sx={{ bgcolor: blue[500], width: 56, height: 56 }}>
            {attendant.full_name.charAt(0)}
          </Avatar>
          <Box ml={2}>
            <Typography variant="h6">Alex Johnson</Typography>
            <Typography variant="body2" color="text.secondary">
              {attendant.full_name}
            </Typography>
          </Box>
        </Box>

        <Typography variant="body2" color="text.secondary" paragraph>
          Numéro de table: {attendant.table_number}
        </Typography>

        <Typography variant="body2" color="text.secondary" paragraph>
          Numéro de place: {attendant.table_seat}
        </Typography>

        <Box display="flex" justifyContent="space-between" flexDirection={"column"}>
          <MDButton disabled={isLoading} onClick={handleMarkPresent} variant="contained" color="success" size="small">
            Marquer comme présent
          </MDButton>
          <MDButton disabled={isLoading} onClick={handleReset} variant="contained" color="success" size="small">
            Scanner un autre code
          </MDButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProfileCard;
