// AuthContext.js
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    

    const login = (user) => {
        window.localStorage.setItem("user-details", JSON.stringify({...user, access_token: user.access}));
    };

    const logout = () => {
        window.localStorage.removeItem("user-details");
    };

    const savedUser = JSON.parse(window.localStorage.getItem("user-details"));

    const isAuthenticated = savedUser ? true : false;

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
