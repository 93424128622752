import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Footer from 'examples/Footer';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import { Card, Grid } from '@mui/material';
import MDTypography from 'components/MDTypography';
import UploadFiles from './components/UploadFiles';
import useSWRInfinite from "swr/infinite";
import { URLS } from 'services/urls';
import { formatImagePath } from 'components/utils/functions';
import VideoPlayer from 'components/VideoPlayer';
import MDButton from 'components/MDButton';
import { Refresh } from '@mui/icons-material';
import MediasShower from './components/MediasChower';

export default function GalleryImageList() {

    // For MediasChower
    const [defaultMedia, setDefaultMedia] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [images, setImages] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [remaining, setRemaining] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);

    const { data: imgs, size, setSize } = useSWRInfinite((index) => URLS.GALLERY.list(index === 0 ? 1 : index));

    const loadedImages = imgs ? [].concat(imgs.results) : [];

    const handleClose = () => {
        setOpen(false);
        setDefaultMedia(null);
    };

    const handleShow = (defaultMedia) => {
        setDefaultMedia(defaultMedia);
        setOpen(true);
    };

    React.useEffect(() => {

        if(imgs){
            let previousIndex = 0;

            console.log("images", imgs)
            setTotal(imgs[0]?.count);
            setRemaining(imgs[0]?.remaining_pages);
            setCurrentPage(imgs[0]?.current_page);
            setImages([])
            for (let index = 0; index < imgs.length; index++) {
                const element = imgs[index];
                if ( previousIndex != element.current_page ) {
                    previousIndex =  element.current_page;
                    setImages((prev) => [...prev, ...element.results])
                    console.log("Current imges", [...element.results])

                    
                    setRemaining(element?.remaining_pages);
                    setCurrentPage(element?.current_page);
                }
                
            }
        }
    }, [imgs])

    return (

        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <MDTypography variant="h6" color="white">
                                        Gallerie
                                    </MDTypography>
                                    <MDBox>
                                        <UploadFiles />
                                    </MDBox>
                                </Box>
                            </MDBox>
                            <MDBox pt={3}>
                                {images?.length > 0 && <ImageList variant="masonry" cols={4} gap={8}>
                                    {images.map((item, index) => (
                                        <ImageListItem key={`img-${index}`} onClick={() => handleShow(item)} sx={{cursor: "pointer"}}>
                                            {item?.media_type === "IMAGE" ? <img
                                                srcSet={`${formatImagePath(item.file_url)}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                src={`${formatImagePath(item.file_url)}?w=248&fit=crop&auto=format`}
                                                alt={item.name}
                                                loading="lazy"
                                            />
                                            :
                                            <VideoPlayer videoUrl={formatImagePath(item.file_url)} />
                                        }
                                        </ImageListItem>
                                    ))}
                                </ImageList>}
                                <MDBox mt={3} textAlign="center">
                                    {remaining !== 0 && <MDBox>
                                        <MDButton variant="contained" color="secondary" endIcon={<Refresh/>} onClick={() => setSize(size + 1)}>
                                            Voir Plus
                                        </MDButton>
                                    </MDBox>}
                                    <MDTypography variant="h6" color="text.secondary">
                                        {images?.length} fichiers chargés sur {total}
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
                <MediasShower open={open} handleClose={handleClose} defaultMedia={defaultMedia} medias={images} defaultIndex={images?.indexOf(defaultMedia)} />
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}
