import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { API } from "services/axios";
import { URLS } from "services/urls";
import { enqueueSnackbar } from "notistack";
import { Close, CloudUpload, Edit, Password } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Box, LinearProgress, linearProgressClasses } from "@mui/material";
import CustomLinearProgress from "components/ProgressBar";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
  

function LinearProgressWithLabel(props) {
    return (
      <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
        <MDBox sx={{ width: '90%', overflow: 'hidden', mr: 1 }}>
          <CustomLinearProgress {...props} />
        </MDBox>
        <MDBox sx={{ width: '10%' }}>
          <MDTypography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</MDTypography>
        </MDBox>
      </MDBox>
    );
  }
  

export default function UploadFiles({ toEdit, onSuccess }) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    // Form fields
    const [files, setFiles] = React.useState(null);
    const [uploadProgress, setUploadProgress] = React.useState(0);

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles(selectedFiles);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });

        setIsLoading(true);

        try {
            await API.post(URLS.GALLERY.add, formData, {
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                },
            });
            setFiles([]); // Clear files after upload
            setIsLoading(false);
            enqueueSnackbar(`Vous avez rajouté ${files?.lenght} fichiers avec succès`, {
                variant: "success",
                autoHideDuration: 3000,
                preventDuplicate: true,
            });
            handleClose();
        } catch (error) {
            enqueueSnackbar("Une erreur est survenue lors des fichiers", {
                variant: "error",
                autoHideDuration: 3000,
                preventDuplicate: true,
            });
            setIsLoading(false);
        } finally {
            setUploadProgress(0);
        }
    };

    return (
        <React.Fragment>
            {toEdit ?
                <IconButton size="small" onClick={handleClickOpen}>
                    <Edit />
                </IconButton>
                :
                <MDButton
                    variant="contained"
                    color="info"
                    size="small"
                    sx={{ mr: 1 }}
                    onClick={handleClickOpen}
                >
                    Téléverser des fichiers
                </MDButton>}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Téléverser des fichiers 
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <MDBox mb={2}>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUpload />}
                            sx={{
                                "& .MuiButton-startIcon": {
                                    color: "white !important",
                                }
                            }}
                        >
                            <span style={{ color: "#fff" }}>Choisir un ou plusieurs fichiers</span>
                            <VisuallyHiddenInput
                                type="file"
                                onChange={handleFileChange}
                                multiple
                                accept="image/*,video/*"
                            />
                        </Button>
                        {uploadProgress > 0 && <Box sx={{ width: '100%' }}>
                            <LinearProgressWithLabel variant="determinate" value={uploadProgress} />
                        </Box>}
                        <MDBox mt={2}>
                            <ImageList cols={3} rowHeight={164} gap={8} sx={{ "& .MuiImageListItem-root": {
                                overflow: "hidden",
                            }}}>
                                {files?.map((file, index) => (
                                    <ImageListItem key={`ky-${index}`} overflow="hidden">
                                        <IconButton sx={{ position: "absolute", top: 2, right: 2, backgroundColor: "rgba(0,0,0,0.3)" }} color="error" size="small" onClick={() => setFiles(files.filter((f, i) => i !== index))}>
                                            <Close />
                                        </IconButton>
                                        {
                                        file.type.startsWith('image/') ? <img
                                            srcSet={`${URL.createObjectURL(file)}`}
                                            src={`${URL.createObjectURL(file)}`}
                                            alt={file.name}
                                            loading="lazy"
                                        />
                                        :
                                        <video width="100%" controls>
                                            <source src={URL.createObjectURL(file)} type={file.type} />
                                            Your browser does not support the video tag.
                                        </video>
                                    }
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </MDBox>
                    </MDBox>
                </DialogContent>
                <DialogActions>
                    <MDBox mt={4} mb={1}>
                        <MDButton disabled={isLoading || !files} variant="gradient" color="info" fullWidth onClick={handleUpload}>
                            Téléverser
                        </MDButton>
                    </MDBox>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
