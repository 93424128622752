import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from '@mui/material/MenuItem';
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { API } from "services/axios";
import { URLS } from "services/urls";
import { enqueueSnackbar } from "notistack";
import { CloudUpload, Edit, Password } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import Switch from "@mui/material/Switch";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function ImportAttendants({ toEdit, onSuccess }) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    // Form fields
    const [file, setFile] = React.useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleAddAttendant = () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", file);

        API.post(URLS.ATTENDANT.import, formData)
            .then((resp) => {
                console.log(resp.data);
                setIsLoading(false);
                enqueueSnackbar("La liste a bien été importée", {
                    variant: "success",
                    autoHideDuration: 3000,
                    preventDuplicate: true,
                });
                if (onSuccess) {
                    onSuccess();
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
                enqueueSnackbar("Une erreur est survenue lors de l'import de la liste", {
                    variant: "error",
                    autoHideDuration: 3000,
                    preventDuplicate: true,
                });
            })
    }


    return (
        <React.Fragment>
            {toEdit ?
                <IconButton size="small" onClick={handleClickOpen}>
                    <Edit />
                </IconButton>
                :
                <MDButton
                    variant="contained"
                    color="info"
                    size="small"
                    sx={{ mr: 1 }}
                    onClick={handleClickOpen}
                >
                    Importer des invités
                </MDButton>}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Importation des invités
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <MDBox mb={2}>
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUpload />}
                            sx={{
                                "& .MuiButton-startIcon": {
                                    color: "white !important",
                                }
                            }}
                        >
                            <span style={{ color: "#fff" }}>Choisir un fichier</span>
                            <VisuallyHiddenInput
                                type="file"
                                onChange={(event) => setFile(event.target.files[0])}
                                multiple
                            />
                        </Button>
                        <br />
                        <MDTypography variant="caption" color="text">
                            Format attendu : Excel
                        </MDTypography>
                        <br />
                        <MDTypography variant="caption" color="text">
                            {file?.name}
                        </MDTypography>
                    </MDBox>
                </DialogContent>
                <DialogActions>
                    <MDBox mt={4} mb={1}>
                        <MDButton disabled={isLoading || !file} variant="gradient" color="info" fullWidth onClick={handleAddAttendant}>
                            Importer la liste
                        </MDButton>
                    </MDBox>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
