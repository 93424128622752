import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from '@mui/material/MenuItem';
import MDButton from "components/MDButton";
import { API } from "services/axios";
import { URLS } from "services/urls";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { Scanner } from '@yudiel/react-qr-scanner';
import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ProfileCard from "./ProfileCard";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export default function ScanCode({ toEdit, onSuccess }) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [attendant, setAttendant] = React.useState(null);

    // Form fields
    const [file, setFile] = React.useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleScannedCode = (result) => {

        if (result.length > 0) {
            setIsLoading(true);
            API.get(URLS.ATTENDANT.search("", `&access_code=${result[0]?.rawValue}`)).then((res) => {
                setIsLoading(false);
                if (res.data.length > 0) {
                    setAttendant(res.data[0]);
                } else {
                    enqueueSnackbar("Code non reconnu", { variant: "error" });
                }
            }).catch((err) => {
                setIsLoading(false);
                enqueueSnackbar(err?.response?.data?.detail, { variant: "error" });
            });
        }
    }

    const handleReset = () => {
        setAttendant(null);
        setIsLoading(false);
    }


    return (
        <React.Fragment>
            <MDButton
                variant="contained"
                color="info"
                size="small"
                sx={{ mr: 1 }}
                onClick={handleClickOpen}
            >
                Scanner un Qr Code
            </MDButton>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Scanner un Qr Code
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ minHeight: "350px"}}>
                    {(!isLoading && !attendant) && <Scanner onScan={handleScannedCode} />}
                    {isLoading && <MDBox>
                        <MDBox textAlign="center" mt={3}>
                            <CircularProgress />
                            <MDTypography variant="h6" color="text">Chargement...</MDTypography>
                        </MDBox>
                    </MDBox>}
                    {attendant && <ProfileCard attendant={attendant} handleReset={handleReset} />}
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
}
