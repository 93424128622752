import { getUserDetails, setUserDetails } from 'components/utils/functions';
import { AUTHAPI } from './axios';
import { URLS } from './urls';


export const refreshToken = async () => {
    let token = getUserDetails();
    const newToken = await AUTHAPI.post(URLS.AUTHS.REFRESH, {
        refresh_token: token.refresh_token
    })
        .then((resp) => {
            token.access_token = resp.data?.access_token;
            setUserDetails(token);
            return resp.data?.access_token;
            // console.log(resp.data?.access_token)
        })
        .catch(() => {
            window.localStorage.setItem('redirect', window.location.pathname);
            window.localStorage.removeItem('user-details');
            window.location.pathname = '/login';
            // console.log(error)
        });
    return newToken;
};
