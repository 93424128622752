/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/login-bg.jpg";
import { API } from "services/axios";
import { URLS } from "services/urls";
import { enqueueSnackbar } from 'notistack'
import { useAuth } from "layouts/private/AuthContext";


function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [accessCode, setAccessCode] = useState("");

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const navigate = useNavigate();

  const { login } = useAuth();

  const handleLogin = () => {

    if(accessCode.length < 5) {
      alert("Le code d'acces doit contenir 5 caractères minimum");
      return;
    }
    setIsLoading(true);
    const data = {
      username: accessCode,
      password: accessCode
    }

    API.post(URLS.AUTHS.LOGIN, data).then((resp) => {
      login(resp.data);
      navigate("/dashboard");
      setIsLoading(false);
      window.location.reload();
    }).catch((error) => {
      enqueueSnackbar("Votre code d'accès est incorrect", {
        variant: "error",
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
      setIsLoading(false);
    })
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Connexion
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDTypography mb={2} sx={{lineHeight: 1.5, fontSize: 12}} variant="body2" fontWeight="regular" color="text">
            Entrer votre code d&apos;acces pour vous connecter a la plateforme.
          </MDTypography>
          <MDBox component="form" role="form" onSubmit={(e) => e.preventDefault()}>
            <MDBox mb={2}>
              <MDInput value={accessCode} type="password" label="Code d'acces" fullWidth onChange={(e) => setAccessCode(e.target.value)} />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Se souvenir de moi
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton onClick={handleLogin} disabled={isLoading} variant="gradient" color="info" fullWidth>
                connexion
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
